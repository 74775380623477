import { Injectable } from "@angular/core";
import { HttpInterceptor } from "@angular/common/http";
import { OperationService } from "./operation.service";
import { switchMap } from "rxjs/operators";

import { envEnums } from "./enums/envEnums";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(
    private operationService: OperationService
  ){ }

  intercept(req, next) {
    const token = localStorage.getItem("token");
    // console.log(req);
    let tokenizedRequest;

    //dev operations
    // if( req.url.includes(envEnums.envDev) || req.url.includes(envEnums.envProd) || req.url.includes(envEnums.envProd2) || req.url.includes(envEnums.envProd3) || req.url.includes(envEnums.envProd4) || req.url.includes(envEnums.envProd5) || req.url.includes(envEnums.envProd6) || req.url.includes(envEnums.envProd7) ){
    if( envEnums.some(enumUrl => req.url.includes(enumUrl)) ){

      // let token = localStorage.getItem('token');
      let data = JSON.parse(atob(token.split('.')[1]))

      let prodEnvs = envEnums.filter((enumUrl, index) => index !== 0);
      
       // Obtener un nuevo token de la solicitud de inicio de sesión por URL
      let prodFlag =  prodEnvs.some(enumUrl => req.url.includes(enumUrl));
      return this.operationService.authApiValidator(data.id, prodFlag).pipe(
        switchMap((response) => {

          // Establecer el nuevo token en la cabecera Authorization de la solicitud
          const newToken = response;
          const authRequest = req.clone({
            setHeaders: {
              Authorization: `Bearer ${newToken}`
            }
          });

          // Enviar la solicitud con el nuevo token
          return next.handle(authRequest);
        })
      );
    }else{
      tokenizedRequest = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
      return next.handle(tokenizedRequest);
    }

  }
}
