import {
  Component,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  Inject,
  Renderer2,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  HostListener,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LayoutService } from "../services/layout.service";
import { Subscription } from "rxjs";
import { ConfigService } from "../services/config.service";
import { DOCUMENT } from "@angular/common";
import { CustomizerService } from "../services/customizer.service";
import { FormControl } from "@angular/forms";
import { LISTITEMS } from "../data/template-search";
import { Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { OperationService } from "app/operation.service";
import { MatAccordion } from "@angular/material/expansion";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "app/user.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./myAccount.component.html",
  styleUrls: ["./myAccount.component.scss"]
})
export class MyAccountComponent implements OnInit {


  newPassword: string = '';
  @ViewChild(MatAccordion) accordion: MatAccordion;
  

  constructor(
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private userService: UserService
  ) {

    
  }  

  ngOnInit() {
   
  }

  changePassword = () => {

    if(this.newPassword == ''){

      this._snackBar.open(this.translate.instant('emptyFields'), this.translate.instant('Close'), {
        duration: 3000,
        horizontalPosition: "center",
        verticalPosition: "top",
        panelClass: ["error-snack-bar"],
      });

      return;

    }else{

      this.userService.updatePasswordUser( this.newPassword, true ).then( response => {

        this.newPassword = '';
  
        this._snackBar.open(this.translate.instant('updatedInformationSuccessfully'), this.translate.instant('Close'), {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["success-snack-bar"],
        });
  
      }).catch(error => {
  
        this._snackBar.open(this.translate.instant('errorUpdateInformation'), this.translate.instant('Close'), {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["error-snack-bar"],
        });
  
      });

    }


  }


  
}