import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';



@Component({
  selector: 'app-direcciones',
  templateUrl: './direcciones.component.html',
  styles: []
})

export class DireccionesComponent implements OnInit {

  constructor(
    private serviceAuth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ){}

  ngOnInit(): void {
    let token: string = this.serviceAuth.getToken();

    const isLeftout = this.route.snapshot.paramMap.get('leftout') === 'true';

    let pathDispatch: string = `${environment.NET_INTEGRATION_URL}/?token=${token}`;
    // let pathDispatch: string = 'https://clientes.tet4d.com/?token='+token;
    // let pathLeftout: string = 'https://clientes.tet4d.com/operations/data-loader-db?token='+token;

    if(isLeftout){
      window.location.assign(pathDispatch);
      // window.open(pathLeftout, '_blank');
    } else {
      window.location.assign(pathDispatch);
    }
  }
}
