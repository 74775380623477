import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { companyEnums } from './enums/companyEnums';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    constructor(private router: Router,
                private translate: TranslateService) {
        
    }

    ngOnInit() {

        const language = localStorage.getItem('lang');
        const company = localStorage.getItem('companyMongoId');

        // console.log(`default language app component => ${language}`);

        if(language){
            this.translate.setDefaultLang(language);
            this.translate.use(language);
            localStorage.setItem('lang', language);
        }
        
        if(company && company !== companyEnums.prisaMongoId){
            const token = localStorage.getItem('token');
            window.location.href = `https://clientes.tet4d.com?token=${token}`;
        }

        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}