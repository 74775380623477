/// <reference path="../../../node_modules/@types/googlemaps/index.d.ts" />
import { WayPoint } from "../../app/shared/data/waitPoint";

const STEPS: WayPoint[] = [
  // {
  //   name: "CASTAÑO",
  //   location: { lat: -33.37619355899602, lng: -70.75970306675772 },
  //   stopover: true,
  // },
  // {
  //   name: "CMPC",
  //   location: { lat: -33.430108, lng: -70.60276 },
  //   stopover: true,
  // },
  // {
  //   name: "COPEFRUT",
  //   location: { lat: -33.432651, lng: -70.624861 },
  //   stopover: true,
  // },
]

const STEPS2: WayPoint[] = [
  // {
  //   name: "COPEFRUT",
  //   location: { lat: -33.37619355899602, lng: -70.75970306675772 },
  //   stopover: true,
  // },
  // {
  //   name: "IANSA_ALIMENTOS_S.A",
  //   location: { lat: -33.443145, lng: -70.597565 },
  //   stopover: true,
  // },
  // {
  //   name: "CASTAÑO",
  //   location: { lat: -33.46182, lng: -70.614952 },
  //   stopover: true,
  // },
]

const STEPS3: WayPoint[] = [
  // {
  //   name: "IANSA_ALIMENTOS_S.A",
  //   location: { lat: -33.37619355899602, lng: -70.75970306675772 },
  //   stopover: true,
  // },
  // {
  //   name: "CASTAÑO",
  //   location: { lat: -33.414741, lng: -70.565355 },
  //   stopover: true,
  // },
  // {
  //   name: "COPEFRUT",
  //   location: { lat: -33.454641, lng: -70.566555 },
  //   stopover: true,
  // },
  // {
  //   name: "AGROCOMMERCE_S.A.",
  //   location: { lat: -33.430942, lng: -70.564912 },
  //   stopover: true,
  // },
]

const STEPS4: WayPoint[] = [
  // {
  //   name: "CASTAÑO",
  //   location: { lat: -33.37619355899602, lng: -70.75970306675772 },
  //   stopover: true,
  // },
  // {
  //   name: "AGROCOMMERCE_S.A.",
  //   location: { lat: -33.4161, lng: -70.59275 },
  //   stopover: true,
  // },
  // {
  //   name: "COPEFRUT",
  //   location: { lat: -33.40754, lng: -70.586014 },
  //   stopover: true,
  // },
]

const STEPS5: WayPoint[] = [
  // {
  //   name: "COPEFRUT",
  //   location: { lat: -33.37619355899602, lng: -70.75970306675772 },
  //   stopover: true,
  // },
  // {
  //   name: "IANSA_ALIMENTOS_S.A",
  //   location: { lat: -33.416152, lng: -70.609187 },
  //   stopover: true,
  // },
  // {
  //   name: "CASTAÑO",
  //   location: { lat: -33.403103, lng: -70.59522 },
  //   stopover: true,
  // },
]


export { STEPS , STEPS2, STEPS3, STEPS4, STEPS5 }