import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";


@Injectable({
  providedIn: "root",
})
export class UserService {
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  prodUrl: string = `https://api.prod.tet4d.com`; 
  devUrl: string = `https://dev.api.tet4d.com`; 

  constructor(private http: HttpClient) { }

  updatePasswordUser(newPassword: string, prod: Boolean){

    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.put(`${url}/validator/users/reset/password`, { newPassword }).toPromise();

  }

}
