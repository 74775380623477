import { Component, Input, OnInit } from '@angular/core';
import { FileService } from "../file.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-file-loader',
  templateUrl: './file-loader.component.html',
  styleUrls: ['./file-loader.component.scss']
})
export class FileLoaderComponent implements OnInit {
  @Input() type: string;
  @Input() selfLoad: boolean = false;
  @Input() templateButton: boolean;
  @Input() upload: () => void;
  @Input() files: any[] = [];
  @Input() parentSetter: (json: any) => void;
  @Input() local: boolean = false;
  cloudIconUrl = 'assets/img/icons/cloud-download-white.svg';
  blueCloudIconUrl = 'assets/img/icons/cloud-download-blue.svg';
  iconUrl: string;
  svgIconUrl: string;

  constructor(
    private fileService: FileService,
    private router: Router
  ) { }

  ngOnInit(): void {
    switch (this.type) {
      case "trucks":
        this.iconUrl = 'assets/img/icons/truck.svg';
        this.svgIconUrl = 'assets/img/icons/csv-file-vehicle.svg';
        break;
      case "products":
        this.iconUrl = 'assets/img/icons/products-icon.svg';
        this.svgIconUrl = 'assets/img/icons/csv-file-product.svg';
        break

      case "clients":
        this.iconUrl = 'assets/img/icons/clients.svg';
        this.svgIconUrl = 'assets/img/icons/csv-file-clients.svg';
        break
      default:
        this.iconUrl = '';
        this.svgIconUrl = '';
        break;
    }
  }

  handleFileInput(files: FileList) {
    this.fileService.manageUploadedFile(files.item(0), this.type, this.local);
    this.files.push(files.item(0));
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }
    this.csvToJson(files[0])
    this.fileService.manageUploadedFile(files[0], this.type);
  }

  // upload() {
  //   if (this.files[0]) {
  //     this.fileService.uploadFile(this.type).subscribe(
  //       success => {
  //         if (success) {
  //           console.log("success")
  //           // this.router.navigateByUrl('/operations');
  //         } else {
  //           console.log('Error in the backend');
  //         }
  //       }
  //     )
  //   } else {
  //     console.log("missing file")
  //   }

  // }

  csvToJson = async (csv: File) => {
    var csvText = await csv.text();
    var lines = csvText.split('\n');
    var result = [];
    var headers = lines[0].split(',').map(item => item.trim());
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      if (lines[i][0] == '"' && lines[i][lines[i].length - 2] == '"') lines[i] = lines[i].slice(1, lines[i].length - 2);
      var currentline = lines[i].match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g);
      // var currentline = lines[i].split(',').map(item => item.trim());
      if (currentline) {
        for (var k = 0; k < headers.length; k++) {
          obj[headers[k]] = currentline[k].replace(/["]/g, '').replace(',', '.');
        }
        result.push(obj);
      }
    }
    if (this.parentSetter) {
      this.parentSetter(result);
    }
    return result;
  }

}
