import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, groupBy, map, tap } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TruckService } from "./truck.service";
import { Operation } from "./shared/data/operation";
import { environment } from "environments/environment";
import { Truck } from "./shared/data/truck";
import { Product } from "./shared/data/product";
import { WayPoint } from "./shared/data/waitPoint";
import { TruckDetails } from "./shared/data/operationTrucks";
import { Client } from "./shared/data/client";
import * as FileSaver from 'file-saver';


@Injectable({
  providedIn: "root",
})
export class ApiValidatorService {
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  prodUrl: string = `https://api.prod.tet4d.com`; 
  devUrl: string = `https://dev.api.tet4d.com`; 

  constructor(private http: HttpClient) { }

  getUserInformation(): Observable<any> {
    let url = this.prodUrl;
    let userId = localStorage.getItem('userId');
    return this.http.get(`${url}/validator/user/${userId}`)
      .pipe(map(company => { return company }));
  }

  getCompanyInformation(id: string): Observable<any> {
    let url = this.prodUrl;
    return this.http.get(`${url}/validator/companies/${id}`)
      .pipe(map(company => { return company }));
  }

}


