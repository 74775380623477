import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  HostListener,
} from "@angular/core";
import { ROUTES } from "./vertical-menu-routes.config";
import { HROUTES } from "../horizontal-menu/navigation-routes.config";

import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from "ngx-device-detector";
import { ConfigService } from "../services/config.service";
import { Subscription } from "rxjs";
import { LayoutService } from "../services/layout.service";
import { NotificationService } from "app/notification.service";
import { AuthService } from "../auth/auth.service";
import { roleEnums } from "app/enums/roleEnums";

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  styleUrls: ['./vertical-menu.component.scss'],
  animations: customAnimations,
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("toggleIcon") toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  // logoUrl = "assets/img/tet4dIcon.png";
  logoUrl = "assets/img/logos/logo-tet4d-blanco.png";
  // textUrl = "assets/img/logoTextWhite.svg";
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;

  totalNotifications: number = 0;

  useRouteOptimizer: boolean = localStorage.getItem('companyRouteOptimizer') == 'true' ? true : false;

  adminRoles = [
    roleEnums.administrator,
    roleEnums.adminTet4d
  ]

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }

  ngOnInit() {
    // this.menuItems = ROUTES;
    
    this.getUserNotificationsNotReaded();
    
    setInterval(() => {
      this.getUserNotificationsNotReaded();
    }, 15000);
    
    // this.authService.data$.subscribe(subject => {
    //   console.log(subject);
      
    //   this.useRouteOptimizer = subject;
    //   this.cdr.detectChanges();
    // })

    this.loadMenuOptions();

  }

  async getUserNotificationsNotReaded(){

    // console.log(`calling notifications`);

    let userId = localStorage.getItem('userId');

    await this.notificationService.getUserNotifications(userId, true).subscribe( (response: any) => {
      const { totalNotifications } = response;

      this.totalNotifications = totalNotifications;

      this.changeDetector.detectChanges();
    });


  }


  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe(
      (templateConf) => {
        if (templateConf) {
          this.config = templateConf;
        }
        this.useRouteOptimizer = localStorage.getItem('companyRouteOptimizer') === 'true' ? true : false;
        this.loadLayout();
        this.cdr.markForCheck();
      }
    );

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      (collapse) => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      }
    );
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(
      (() => {
        this.innerWidth = event.target.innerWidth;
        this.loadLayout();
      }).bind(this),
      500
    );
  }

  loadLayout() {
    if (this.config.layout.menuPosition === "Top") {
      // Horizontal Menu
      if (this.innerWidth < 1200) {
        // Screen size < 1200
        this.menuItems = HROUTES;
      }
    } else if (this.config.layout.menuPosition === "Side") {
      // Vertical Menu{
      // this.menuItems = ROUTES;
      this.loadMenuOptions()
    }

    // if (this.config.layout.sidebar.backgroundColor === "white") {
    //   this.logoUrl = "assets/img/tet4dIcon.png";
    // } else {
    //   this.logoUrl = "assets/img/tet4dIcon.png";
    // }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }
  
  isSpaceweareCompany(){
    let token = localStorage.getItem('token');
    let data = JSON.parse(atob(token.split('.')[1]))

    if(data.spCompanyId == 2)
      return true;
    else 
      return false;
  }

  loadMenuOptions(){
    this.menuItems = ROUTES;

    this.menuItems.forEach( menuItem => {
  
      if(menuItem.title == 'Operaciones'){

        if(!this.isSpaceweareCompany()){

          menuItem.submenu = menuItem.submenu.filter( subMenuItem => {
            return subMenuItem.title != 'devOperations'
          });

        }

        if(!this.useRouteOptimizer){

          menuItem.submenu = menuItem.submenu.filter( subMenuItem => {
            return subMenuItem.title != 'multiRouteOperations'
          });

        }

        if( !this.adminRoles.includes( this.authService.getIdRole() )){

          menuItem.submenu = menuItem.submenu.filter( subMenuItem => {
            return subMenuItem.title != 'restoreOperations'
          });

        }

        if(this.authService.getCompanyId() != '11' ){

          menuItem.submenu = menuItem.submenu.filter( subMenuItem => {
            return subMenuItem.title != 'statusPlannedRoute'
          });

        }

      }

    });

    if(this.authService.getIdRole() == '11' || this.authService.getIdRole() == '13' ){

      this.menuItems = this.menuItems.filter( item => {
        return item.title != 'getOrders'
      });

    }

    if(this.authService.getIdRole() != '3'  ){

      this.menuItems = this.menuItems.filter( item => {
        return item.title != 'metabase'
      });

    }

  }

  getTranslation(key: string){

    const translatedKey = this.translate.instant(key);

    return translatedKey;

  }

}
