import { Client } from "../../app/shared/data/client";

export const CLIENTS: Client[] = [
  // {
  //   id: 1,
  //   name: "CASTAÑO",
  //   rut: "79.746.250-0",
  //   address: "LAUTARO 361",
  //   longitude: -33.3439008,
  //   latitude: -70.705468,
  //   commune: "QUILICURA",
  //   region: "METROPOLITANA",
  //   time: 45,
  //   time_window: "9 a 12 hrs",
  //   kms: 80,
  //   route_time: 45,
  //   total_time: 57,
  //   order_quantity: 14,
  // },
  // {
  //   id: 2,
  //   name: "CMPC",
  //   rut: "90222000-3",
  //   address: "Eyzaguirre 01098",
  //   longitude: -33.6112808,
  //   latitude: -70.5674864,
  //   commune: "PUENTE ALTO",
  //   region: "METROPOLITANA",
  //   time: 45,
  //   time_window: "12 a 15 hrs",
  //   kms: 80,
  //   route_time: 45,
  //   total_time: 57,
  //   order_quantity: 14,
  // },
  // {
  //   id: 3,
  //   name: "AGROCOMMERCE_S.A.",
  //   rut: "96973910",
  //   address: "AVENIDA_JOSE_MIGUEL_INFANTE_8745",
  //   longitude: -33.405414,
  //   latitude: -70.5674864,
  //   commune: "RENCA",
  //   region: "METROPOLITANA",
  //   time: 45,
  //   time_window: "24 hrs",
  //   kms: 80,
  //   route_time: 45,
  //   total_time: 57,
  //   order_quantity: 14,
  // },
  // {
  //   id: 4,
  //   name: "KS_CHILE_S.A",
  //   rut: "76541630",
  //   address: "BOULEVARD_PONIENTE_460",
  //   longitude: -33.42782,
  //   latitude: -70.794033,
  //   commune: "SANTIAGO",
  //   region: "METROPOLITANA",
  //   time: 45,
  //   time_window: "24 hrs",
  //   kms: 80,
  //   route_time: 45,
  //   total_time: 57,
  //   order_quantity: 14,
  // },
  // {
  //   id: 5,
  //   name: "IANSA_ALIMENTOS_S.A",
  //   rut: "76724603",
  //   address: "ISMAEL_BRICENO_1548",
  //   longitude: -33.37553,
  //   latitude: -70.726258,
  //   commune: "QUILICURA",
  //   region: "METROPOLITANA",
  //   time: 30,
  //   time_window: "9 a 15 hrs",
  //   kms: 80,
  //   route_time: 45,
  //   total_time: 57,
  //   order_quantity: 14,
  // },
  // {
  //   id: 6,
  //   name: "TF_LOGISTICS_SPA",
  //   rut: "77164337",
  //   address: "AVENIDA_LOS_ACACIOS_1696",
  //   longitude: -33.404445,
  //   latitude: -70.692866,
  //   commune: "RENCA",
  //   region: "METROPOLITANA",
  //   time: 30,
  //   time_window: "12 hrs",
  //   kms: 80,
  //   route_time: 45,
  //   total_time: 57,
  //   order_quantity: 14,
  // },
  // {
  //   id: 7,
  //   name: "CMPC_TISSUE_S.A",
  //   rut: "96529310",
  //   address: "AVENIDA_EYZAGUIRRE_01098",
  //   longitude: -33.611134,
  //   latitude: -70.565095,
  //   commune: "PUENTE ALTO",
  //   region: "METROPOLITANA",
  //   time: 30,
  //   time_window: "24 hrs",
  //   kms: 80,
  //   route_time: 45,
  //   total_time: 57,
  //   order_quantity: 14,
  // },
];

// id: number;
// client: string;
// RUT: string;
// direction: string;
// longitude: number;
// latitude: number;
// comuna: string;
// region: string;
// time: number;
