import {
    Component,
    Output,
    EventEmitter,
    OnDestroy,
    OnInit,
    AfterViewInit,
    ChangeDetectorRef,
    Inject,
    Renderer2,
    ViewChild,
    ElementRef,
    ViewChildren,
    QueryList,
    HostListener,
  } from "@angular/core";
  import { TranslateService } from "@ngx-translate/core";
  import { LayoutService } from "../services/layout.service";
  import { Subscription } from "rxjs";
  import { ConfigService } from "../services/config.service";
  import { DOCUMENT } from "@angular/common";
  import { CustomizerService } from "../services/customizer.service";
  import { FormControl } from "@angular/forms";
  import { LISTITEMS } from "../data/template-search";
  import { Router } from "@angular/router";
  import { AuthService } from "../auth/auth.service";
  import { OperationService } from "app/operation.service";
  import { NotificationService } from "app/notification.service";
  import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
  import { ThemePalette } from '@angular/material/core';
import { notificationEnums } from "./notificationEnums";
  
  @Component({
    selector: "app-notifications",
    templateUrl: "./notifications.component.html",
    styleUrls: ["./notifications.component.scss"]
  })
//   export class NotificationsComponent implements OnInit, AfterViewInit, OnDestroy {
export class NotificationsComponent implements OnInit{

    loading: boolean;

    spinnerColor: ThemePalette = 'primary';
    spinnerMode: ProgressSpinnerMode = 'indeterminate';
    
    notifications: any[];
    totalNotifications: number = 0;
    userId: string;
  
    constructor(
      private notificationService: NotificationService,
      private changeDetector: ChangeDetectorRef
    ) {

        this.notifications = [];
        this.totalNotifications = 0;
        this.loading = true;
        this.userId = localStorage.getItem('userId');
    
    }  
  
    ngOnInit() {

        this.getUserNotifications();

        setInterval( () => {
            this.getUserNotifications();
        }, 15000);
        
    }

    async getUserNotifications(){

        this.notificationService.getUserNotifications(this.userId, true).subscribe( (response: any) => {
            const { totalNotifications, notifications } = response;

            this.totalNotifications = totalNotifications;
            this.notifications = notifications;
            this.notifications.reverse();

            this.loading = false;


            this.changeDetector.detectChanges();
        });

        
    }

    markAllasRead(){

        this.notificationService.markAllAsRead(this.userId, true).subscribe( (response: any) => {

            const { status } = response;

            if(status == notificationEnums.statusCompleted){
                console.log(`updated`);
                this.notifications = [];
                this.totalNotifications = 0;

                this.changeDetector.detectChanges();
            }else{
                console.log(`not updated`);
            }

        } );

    }

    getClasses(statusNotification){

        // let baseCss = 'font-size-30';
        let baseCss = 'pt-1';

        switch(statusNotification){
            case "success":
                baseCss += ' alert-success';
                break;
            case "failed":
                baseCss += ' alert-danger';
                break;
            case "warning":
                baseCss += ' alert-warning';
                break;

        }

        return baseCss;

    }
  
    
  }