import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";

@Component({
  selector: "app-circular-chart",
  templateUrl: "./circular-chart.component.html",
  styleUrls: ["./circular-chart.component.scss"],
})
export class CircularChartComponent implements OnChanges {
  @Input() current: number;
  @Input() max: number;
  @Input() units: string;
  @Input() svg: string;
  @Input() color: string;
  @Input() total: number;
  @ViewChild("arc") arc: ElementRef;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.current = +this.current.toFixed(2);
    this.max = +this.max.toFixed(2);
    if (!changes["current"]?.isFirstChange()) {
      this.update();
    }
  }

  ngAfterViewInit(): void {
    this.current = +this.current.toFixed(2);
    this.max = +this.max.toFixed(2);
    if (this.current > this.max) {
      // this.color = "#F66060";
      this.arc.nativeElement.setAttribute(
        "d",
        describeArc(80, 80, 60, 0, (359.9 * this.max) / this.max)
      );
    } else {
      // this.color = "#3C4CCA";
      this.arc.nativeElement.setAttribute(
        "d",
        describeArc(80, 80, 60, 0, (359.9 * this.current) / this.max)
      );
    }
    this.cd.detectChanges();;;
  }

  update(): void {
    this.current = +this.current.toFixed(2);
    this.max = +this.max.toFixed(2);
    if (this.current > this.max) {
      // this.color = "#F66060";
      this.arc.nativeElement.setAttribute(
        "d",
        describeArc(80, 80, 60, 0, (359.9 * this.max) / this.max)
      );
    } else {
      // this.color = "#3C4CCA";
      this.arc.nativeElement.setAttribute(
        "d",
        describeArc(80, 80, 60, 0, (359.9 * this.current) / this.max)
      );
    }
    console.log("Updating circular chart");
  }
}

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = ((angleInDegrees - 180) * Math.PI) / 180;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  //     console.log(d);
  return d;
}
