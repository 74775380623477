import { Directive, ElementRef, HostListener, HostBinding, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appDragndrop]'
})
export class DragndropDirective {

  @HostBinding('class.fileover') fileOver: boolean;
  @HostBinding('class.fileout') fileOut: boolean;
  @HostBinding('class.fill') fill: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  constructor(el: ElementRef) {
   }

  @HostListener('dragover', ['$event']) onDragOver (evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = true;
    this.fileOut = false;

    console.log('Drag Over');
   }

  @HostListener('dragleave', ['$event']) public onDragLeave (evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = false;
    this.fileOut = true;

    console.log('Drag Leave');
  }
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = false;
    this.fileOut = false;
    this.fill = true;
    const files: any[] = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files)
      console.log(`you dropped a file here`);
    }
  }

}
