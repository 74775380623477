import { Injectable } from '@angular/core';
import { Client } from "./shared/data/client";
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CLIENTS } from "../assets/data/mock-clients";
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  clients: Client[] = CLIENTS;

  constructor(private http: HttpClient) { }

  getClients() {
    // console.log('Fetching clients');
    return this.http.get<any>(`${environment.baseUrl}/clients`).pipe(
      tap(_ => console.log('Clients fetched')),
      map(data => data.clients)
    );
  }

  getLocalClients(id = '') {
    // console.log('Fetching local clients');
    return this.http.get<any>(`${environment.baseUrl}/clients/local/${id}`).pipe(
      tap(_ => console.log('Local clients fetched')),
      map(data => data)
    );
  }

  getLocalClusters(id = '') {
    // console.log('Fetching local clients');
    return this.http.get<any>(`${environment.baseUrl}/clusters/local/${id}`).pipe(
      tap(_ => console.log('Local clients fetched')),
      map(data => data)
    );
  }

  checkCoordinates(coordinates: any) {
    let formData = new FormData();
    // console.log('Fetching coordinate check');
    return this.http.post<any>(`${environment.baseUrl}/coordinateCheck/validate/${coordinates.long}/${coordinates.lat}`, formData).pipe(
      tap(_ => console.log('Checking coordinates')),
      map(data => data)
    );
  }

  checkAllCoordinates(id: string) {
    let formData = new FormData();
    console.log('Fetching coordinate all check');
    return this.http.post<any>(`${environment.baseUrl}/coordinateCheck/validate/${id}`, formData).pipe(
      tap(_ => console.log('Checking coordinates')),
      map(data => data)
    );
  }

  saveLocalClients(file: File, id = '') {
    let headers = new HttpHeaders();
    let formData = new FormData();
    formData.append('clients', file);
    return this.http.post<any>(`${environment.baseUrl}/clients/local/${id}`, formData, { headers });
  }

  saveLocalClusters(file: File, id = '') {
    let headers = new HttpHeaders();
    let formData = new FormData();
    formData.append('clusters', file);
    return this.http.post<any>(`${environment.baseUrl}/clusters/local/${id}`, formData, { headers });
  }

  getClient(id: string): Observable<Client> {
    console.log(`fetching client with id=${id}`);
    return of(this.clients.filter((t) => t.id === id)[0])


    // const url = `${this.clientsUrl}/${id}`;
    // return this.http.get<Client>(url)
    //   .pipe(
    //     tap(_ => console.log(`ClientService: fetched Client id = ${id}`)),
    //     catchError(this.handleError<Client>(`getHero id=${id}`))
    //   );
  }

  updateClient(client: Client): Observable<any> {
    console.log(`updating Client with id=${client.id}`);
    var clientIndex: number = this.clients.findIndex(t => t.id === client.id);
    this.clients[clientIndex] = client;
    return of(client);


    // return this.http.put(this.clientsUrl, Client, this.httpOptions)
    //   .pipe(
    //     tap(_ => console.log(`updated client id=${Client.id}`)),
    //     catchError(this.handleError<any>('updateClient'))
    //   );
  }

  addClient(client: Client): Observable<Client> {
    console.log(`adding client with id=${client.id}`);
    var { id } = this.clients.reduce((a, c) => { return c > a ? c : a });
    client.id = id + 1;
    this.clients.push(client);
    return of(client);
    // return this.http.post<Client>(this.clientsUrl, Client, this.httpOptions)
    //   .pipe(
    //     tap((newClient: Client) => console.log(`added Client w/ id=${newClient.id}`)),
    //     catchError(this.handleError<Client>('addClient'))
    //   );
  }



  deleteClient(client: Client | number): Observable<Client> {
    const id = typeof client === 'number' ? client : client.id;
    const delClient = this.clients.find(t => t.id === id);
    console.log(`deleting client with id = ${delClient.id}`);
    this.clients = this.clients.filter(t => t.id !== id);
    return of(delClient)

    // return this.http.delete<Client>(url, this.httpOptions).pipe(
    //   tap(_ => console.log(`deleted Client id=${id}`)),
    //   catchError(this.handleError<Client>('deleteClient'))
    // )
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    }
  };

  createClientsDB(clients): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/clients`, { data: clients }, this.httpOptions)
      .pipe(map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      ));
  }

  deleteClientsDB(operation): Observable<any> {
    return this.http.delete<any>(`${environment.baseUrl}/clients/${operation}`)
      .pipe(map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      ));
  }

  updateClientsDB(routes, operation): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/clients/update`, { data: routes, operationId: operation }, this.httpOptions)
      .pipe(map(
        (res) => {
          return res;
        },
        (error) => {
          return error;
        }
      ));
  }
}
