import { Injectable } from '@angular/core';
import { Truck } from "./shared/data/truck";
import { Operation } from "./shared/data/operation";
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WayPoint } from "./shared/data/waitPoint";
import { STEPS, STEPS2, STEPS3, STEPS4, STEPS5 } from "../assets/data/mock-waitpoits";
import { TRUCKS } from "../assets/data/mock-trucks";
import { environment } from 'environments/environment';



@Injectable({
  providedIn: 'root'
})
export class TruckService {

  public trucks: Truck[] = TRUCKS;
  
  private trucksUrl = 'api/trucks';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getTrucks(): Observable<Truck[]> {
    console.log('Fetching trucks');
    return this.http.get<any>(`${environment.baseUrl}/trucks`).pipe(
      tap(_ => console.log('Trucks fetched')),
      map(data => data.trucks)
    );
  }

  getLocalTrucks(id = '') {
    console.log('Fetching local trucks');
    return this.http.get<any>(`${environment.baseUrl}/trucks/local/${id}`).pipe(
      tap(_ => console.log('Local trucks fetched')),
      map(data => data)
    );
  }

  saveLocalTrucks(file: File, id = '') {
    let headers = new HttpHeaders();
    let formData = new FormData();
    formData.append('trucks', file);
    return this.http.post<any>(`${environment.baseUrl}/trucks/local/${id}`, formData, { headers });
  }

  getTrucksByOperation( operationId: number ): Observable<Truck[]> {
    console.log(`fetching trucks in operation w/ id=${operationId}`);
    return of(this.trucks);

  }

  getTruck(id): Observable<Truck> {
    console.log(`fetching truck with id=${id}`);
    return of(this.trucks.filter((t) => t.id === id)[0])


    // const url = `${this.trucksUrl}/${id}`;
    // return this.http.get<Truck>(url)
    //   .pipe(
    //     tap(_ => console.log(`TruckService: fetched Truck id = ${id}`)),
    //     catchError(this.handleError<Truck>(`getHero id=${id}`))
    //   );
  }

  updateTruck(truck: Truck): Observable<any> {
    console.log(`updating truck with id=${truck.id}`);
    var truckIndex: number = this.trucks.findIndex(t => t.id === truck.id);
    this.trucks[truckIndex] = truck;
    return of(truck) 


    // return this.http.put(this.trucksUrl, truck, this.httpOptions)
    //   .pipe(
    //     tap(_ => console.log(`updated truck id=${truck.id}`)),
    //     catchError(this.handleError<any>('updateTruck'))
    //   );
  }

  addTruck(truck: Truck): Observable<Truck> {
    console.log(`adding truck with id=${truck.id}`);
    var { id } = this.trucks.reduce((a, c) => {return c > a ? c : a });
    truck.id = id + 1;
    this.trucks.push(truck);
    return of(truck);
    // return this.http.post<Truck>(this.trucksUrl, truck, this.httpOptions)
    //   .pipe(
    //     tap((newTruck: Truck) => console.log(`added Truck w/ id=${newTruck.id}`)),
    //     catchError(this.handleError<Truck>('addTruck'))
    //   );
  }



  deleteTruck(truck: Truck | number): Observable<Truck> {
    const id = typeof truck === 'number' ? truck : truck.id;
    const delTruck: Truck = this.trucks.find(t => t.id === id);
    console.log(`deleting truck with id = ${delTruck.id}`);
    this.trucks = this.trucks.filter(t => t.id !== id); 
    return of(delTruck)

    // return this.http.delete<Truck>(url, this.httpOptions).pipe(
    //   tap(_ => console.log(`deleted truck id=${id}`)),
    //   catchError(this.handleError<Truck>('deleteTruck'))
    // )
  }

  getRoute(truck: Truck | number): Observable<WayPoint[]>{
    const id = typeof truck === 'number' ? truck : truck.id;
    if (id === 1) {return of(STEPS);}
    else if (id === 2) {return of(STEPS2);}
    else if (id === 3) {return of(STEPS3);}
    else if (id === 4) {return of(STEPS4);}
    else if (id === 5) {return of(STEPS5);}
    return of(STEPS);
  }



  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    }
  };


}
