import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, groupBy, map, tap } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TruckService } from "./truck.service";
import { Operation } from "./shared/data/operation";
import { environment } from "environments/environment";
import { Truck } from "./shared/data/truck";
import { Product } from "./shared/data/product";
import { WayPoint } from "./shared/data/waitPoint";
import { TruckDetails } from "./shared/data/operationTrucks";
import { Client } from "./shared/data/client";
import * as FileSaver from 'file-saver';

// interface OperationDetails {
//   data: [Operation[], Truck[], Client[], Product[], WayPoint[], Client[], Product[], WayPoint[]];


@Injectable({
  providedIn: "root",
})
export class NotificationService {
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  prodUrl: string = `https://api.prod.tet4d.com`; 
  devUrl: string = `https://dev.api.tet4d.com`; 

  constructor(private http: HttpClient) { }

  markAllAsRead(userId: string, prod: boolean){

    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.get(`${url}/validator/notifications/markAllAsRead/${userId}`);

  }

  getUserNotifications(userId: string, prod: boolean){

    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.get(`${url}/validator/notifications/${userId}`);

  }



}
