import { RouteInfo } from './vertical-menu.metadata';


// let params: UserUrlComponent = new UserUrlComponent();

// let url: string = 'https://datastudio.google.com/u/0/reporting/ae2b6fd5-f90f-43a6-aaef-69bec7d3be22/page/BT5RC?params='+params;


//Sidebar menu Routes and data

const operationsSubMenu: RouteInfo[] = [
  { path: '/operations', title: 'singleRouteOperations', icon: '../../../assets/img/menu-icons/operations-white.svg', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/operations/multiRoute', title: 'multiRouteOperations', icon: '../../../assets/img/menu-icons/operations-white.svg', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/operations/dev', title: 'devOperations', icon: '../../../assets/img/menu-icons/operations-white.svg', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/operations/restoreOperations', title: 'restoreOperations', icon: '../../../assets/img/menu-icons/operations-white.svg', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/operations/statusPlannedRoute', title: 'statusPlannedRoute', icon: '../../../assets/img/menu-icons/operations-white.svg', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
]


export const ROUTES: RouteInfo[] = [
  { path: '/notifications', title: 'notifications', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/home', title: 'Home', icon: '../../../assets/img/menu-icons/home-white.svg', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '', title: 'Operaciones', icon: '../../../assets/img/menu-icons/operations-white.svg', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
          submenu: operationsSubMenu
  }, 
  { path: '/direcciones', title: 'getOrders', icon: '../../../assets/img/menu-icons/map-pin-white.svg', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
  { path: '/reports/download', title: 'reports', icon: '../../../assets/img/menu-icons/reports-white.svg', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/reports/metabase', title: 'metabase', icon: '../../../assets/img/menu-icons/reports-white.svg', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  

];



