export const envEnums = [
    "https://dev.api.tet4d.com/validator/operations",
    "https://api.prod.tet4d.com/validator/operations",
    "https://api.prod.tet4d.com/validator/operation",
    "https://api.prod.tet4d.com/validator/image",
    "https://api.prod.tet4d.com/validator/params",
    "https://api.prod.tet4d.com/validator/cdcenter",
    "https://api.prod.tet4d.com/validator/bulkOperation",
    "https://api.prod.tet4d.com/validator/routes",
    "https://api.prod.tet4d.com/validator/metabase",
    "https://api.prod.tet4d.com/validator/prisa",
    "https://api.prod.tet4d.com/validator/apiCalls",
    "https://api.prod.tet4d.com/validator/users",
    "https://api.prod.tet4d.com/validator/orders2"
];

// export const envEnums = {
//     envDev: "https://dev.api.tet4d.com/validator/operations",
//     envProd: "https://api.prod.tet4d.com/validator/operations",
//     envProd2: "https://api.prod.tet4d.com/validator/operation",
//     envProd3: "https://api.prod.tet4d.com/validator/image",
//     envProd4: "https://api.prod.tet4d.com/validator/params",
//     envProd5: "https://api.prod.tet4d.com/validator/cdcenters",
//     envProd6: "https://api.prod.tet4d.com/validator/bulkOperation",
//     envProd7: "https://api.prod.tet4d.com/validator/routes"
// }
