<div class="chart">
    <div>
        <img *ngIf="svg" [src]="svg" height="25px">

        <h6 *ngIf="total; else noTotal">{{total}} {{units}}</h6>
        <ng-template #noTotal>
            <h6>{{current}}{{units}}</h6>
            <hr class="my-1">
            <h6>{{max}}{{units}}</h6>
        </ng-template>
    </div>
    <svg height="160" width="160" transform='rotate(90) scale(1 1)'>
        <defs>
            <linearGradient [attr.id]="color+'id'" gradientTransform="rotate(90)">
                <stop offset="15%" [attr.stop-color]="color || '#3C4CCA'" />
                <stop offset="100%" [attr.stop-color]="color || '#3C4CCA'" stop-opacity="0.25" />
            </linearGradient>
        </defs>
        <path #arc fill="none" [attr.stroke]="'url(#'+color+'id)'" stroke-width="15" />
    </svg>
</div>