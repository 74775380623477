<div class="row justify-content-start bg-white align-items-center nav-bar" id="title">
    <h2>{{"notifications"| translate}}</h2>
</div>

<div class="container-fluid">
    <div class="row mt-3">
        <div class="col-md-12">

            <div *ngIf="loading" class="d-flex justify-content-center">
                <mat-progress-spinner class="example-margin" [color]="spinnerColor" [mode]="spinnerMode"> </mat-progress-spinner>
            </div>

            <p *mgIf="totalNotifications == 0  && !loading">{{ "noNotifications" | translate }} </p>
            <div *ngIf="totalNotifications > 0 && !loading">
                <div class="row mb-3">
                    <div class="col-md-2">
                        <button (click)="markAllasRead()" class="btn btn-md btn-primary">{{ "markAllAsRead" | translate }}</button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">

                        <div class="row mb-2 ml-1" *ngFor="let notification of notifications">

                            <mat-card class="full-width">
                                <mat-card-content>

                                    <div class="row">
                                        <div class="col-md-1">

                                              <div [class]=" getClasses(notification.status)">
                                                <mat-icon >{{ notification.status == 'failed' ? 'error' : notification.status == 'warning' ? 'warning' : 'check_circle'  }}</mat-icon>
                                              </div>
                                            
                                        </div>
                                        <div class="col-md-11">
                                            <span>{{ notification.createdAt | date: 'dd/MM/yyyy' }} - {{ notification.createdAt | date: 'HH:mm' }}</span>
                                            <p>{{ notification.message }}</p>
                                        </div>
                                    </div>

                                </mat-card-content>
                            </mat-card>

                            
                        </div>

                    </div>
                </div>
                
                
            </div>
            
            <!-- <table mat-table [dataSource]="notifications" class="mat-elevation-z8" *ngIf="totalNotifications > 0 && !loading">
                
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>status</th>
                    <td mat-cell *matCellDef="let notification"> <mat-icon [class]=" getClasses(notification.status)">{{ notification.status == 'failed' ? 'error' : notification.status == 'warning' ? 'warning' : 'check_circle'  }}</mat-icon> </td>
                </ng-container>

                <ng-container matColumnDef="message">
                    <th mat-header-cell *matHeaderCellDef>message</th>
                    <td mat-cell *matCellDef="let notification"> {{notification.message}} </td>
                </ng-container>


            </table> -->
            
        </div>
    </div>
</div>