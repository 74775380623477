<nav class="navbar navbar-expand-lg navbar-light header-navbar {{
    transparentBGClass
  }}" [ngClass]="{
    'navbar-brand-center': menuPosition === 'Top',
    'navbar-static':
      menuPosition === 'Side' && config?.layout.navbar.type === 'Static',
    'navbar-sticky':
      menuPosition === 'Side' && config?.layout.navbar.type === 'Fixed',
    'fixed-top': isSmallScreen && menuPosition === 'Top'
  }">
  <div class="container-fluid navbar-wrapper" id="header-container">

    <div class="navbar-header d-flex">

      <div id="header-menu-button"
        class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>

      <div class="navbar-brand-center">
        <div class="px-2">
        </div>
        
        <div class="navbar-header">

        </div>
      </div>

    </div>

    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        
        <ul class="navbar-nav">                  

          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static">

            <span> {{ userName }} </span>

            <button mat-icon-button [matMenuTriggerFor]="generalMenu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            
            <mat-menu #generalMenu="matMenu">

              <button mat-menu-item [routerLink]="['/myAccount']" >
                <mat-icon>person</mat-icon> <span>{{ "myAccount" | translate }}</span>
              </button>

              <button mat-menu-item [matMenuTriggerFor]="siteLanguages">
                <mat-icon>translate</mat-icon> <span>{{ "siteLanguage" | translate }}</span>
              </button>

              <mat-menu #siteLanguages="matMenu">
                <button mat-menu-item (click)="changeLangEs('es')">{{ "language.spanish" | translate }}</button>
                <button mat-menu-item (click)="changeLangEn('en')">{{ "language.english" | translate }}</button>
                <button mat-menu-item (click)="changeLangFr('fr')">{{ "language.french"  | translate }}</button>
              </mat-menu>
    
              <button mat-menu-item (click)="logout()">
                <mat-icon>settings_power</mat-icon> <span>{{ "Logout" | translate }}</span>
              </button>
    
            </mat-menu>

            
        </ul>

      </div>
    </div>
  </div>
</nav>