import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BackButtonComponent } from "../back-button/back-button.component";
import { CircularChartComponent } from "../circular-chart/circular-chart.component";
import { FileLoaderComponent } from "../file-loader/file-loader.component";
import { DragndropDirective } from "../dragndrop.directive";




@NgModule({
  declarations: [
    BackButtonComponent,
    CircularChartComponent,
    FileLoaderComponent,
    DragndropDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    BackButtonComponent,
    CircularChartComponent,
    FileLoaderComponent,
  ],
})
export class CustomCommonModulesModule { }
