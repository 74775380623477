import { Routes, RouterModule } from '@angular/router';
import { LoginDispatchComponent } from 'app/report-ds/login-dispatch/loginDispatch.component';
import {UserUrlComponent} from '../../report-ds/user-url/user-url.component';
import { DireccionesComponent } from 'app/report-ds/direcciones/direcciones.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { MyAccountComponent } from '../myAccount/myAccount.component';
//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'page',
    loadChildren: () => import('../../page/page.module').then(m => m.PageModule)
  },
  {
    path: 'vehicles',
    loadChildren: () => import('../../truck/truck.module').then(m => m.TruckModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('../../client/client.module').then(m => m.ClientModule)
  },
  // {
  //   path: 'map',
  //   loadChildren: () => import('../../map/map.module').then(m => m.MapModule)
  // },
  // {
  //   path: 'visualization',
  //   loadChildren: () => import('../../visualization3d/visualization3d.module').then(m => m.Visualization3dModule)
  // },
  {
    path: 'operations',
    loadChildren: () => import('../../operations/operations.module').then(m => m.OperationsModule)
  },
  {
    path: 'zones',
    loadChildren: () => import('../../zones/zones.module').then(m => m.ZonesModule)
  },
  {
    path: 'home',
    loadChildren: () => import('../../home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'clusters',
    loadChildren: () => import('../../cluster/cluster.module').then(m => m.ClusterModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('../../reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'dispatch',
    component: LoginDispatchComponent
  },
  {
    path: 'direcciones',
    component: DireccionesComponent
  },
  {
    path: 'direcciones/:leftout',
    component: DireccionesComponent
  },
  {
    path: 'notifications',
    component: NotificationsComponent
  },
  {
    path: 'myAccount',
    component: MyAccountComponent
  }
];
