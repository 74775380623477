<div class="w-100">
    <div class="upload" *ngIf="templateButton">
        <a *ngIf="type === 'products'" href="assets/files/operations_eaZJzqxhSmruSJp6_products.csv"
            download="products-template.csv"><button class="btn btn-info light"> <img [src]="blueCloudIconUrl">
                Products template</button></a>
        <a *ngIf="type === 'trucks'" href="assets/files/operations_eaZJzqxhSmruSJp6_trucks.csv"
            download="vehicle-template.csv"><button class="btn btn-info light"> <img [src]="blueCloudIconUrl"> Vehicle
                template</button></a>
        <a *ngIf="type === 'clients'" href="assets/files/operations_eaZJzqxhSmruSJp6_clients.csv"
            download="clients-template.csv"><button class="btn btn-info light"> <img [src]="blueCloudIconUrl"> Clients
                template</button></a>
    </div>

    <div *ngIf="type === 'products'" class="col-title" id="productsHeader">
        <div class="icon-container products card"><img [src]="iconUrl"></div>
        <h4>Products</h4>
    </div>
    <div *ngIf="type === 'trucks' && !selfLoad" class="col-title" id="trucksHeader">
        <div routerLink="../../../vehicles" class="icon-container trucks card clickable"><img [src]="iconUrl"></div>
        <h4>Trucks</h4>
    </div>
    <div *ngIf="type === 'clients' && !selfLoad" class="col-title" id="clientsHeader">
        <div routerLink="../../../clients" class="icon-container clients card clickable"><img [src]="iconUrl"></div>
        <h4>Clients</h4>
    </div>
    <div *ngIf="type === 'trucks' && selfLoad" class="col-title" id="trucksHeader" #trucksHeader>
        <div class="icon-container trucks card"><img [src]="iconUrl"></div>
        <h4>Trucks</h4>
    </div>
    <div *ngIf="type === 'clients' && selfLoad" class="col-title">
        <div class="icon-container clients card"><img [src]="iconUrl"></div>
        <h4>Clients</h4>
    </div>

    <form class="box" action="" method="post" enctype="multipart/form-data">
        <div appDragndrop class="drop-area card" (fileDropped)="onFileDropped($event)"
            [ngClass]="{'fill': files.length > 0}">
            <input class="clickable" type="file" accept=".csv" id="fileDrop"
                (change)="handleFileInput($event.target.files)" />
            <p>Drag and drop or
                <label for="fileDrop">browse</label>
                your files here
            </p>
            <div class="mb-2"><img [src]="'/assets/img/icons/csvCloud.svg'" alt="Logo"></div>
            <p>CSV File</p>
        </div>
    </form>



    <div *ngIf="files.length > 0">
        <h5>Uploaded file</h5>

        <div *ngFor="let file of files" class="card file"> <img [src]="svgIconUrl" width="30px"> {{file.name}}</div>
    </div>

    <div *ngIf="selfLoad" class="upload">
        <button class="btn btn-info" (click)="upload()"> Upload </button>
    </div>
</div>