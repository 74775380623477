<div class="card mb-5 ml-4 width-95">

  <h2 class="mb-3">{{ "myAccount" | translate }}</h2>

  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel>

      <mat-expansion-panel-header>

        <mat-panel-title class="text-bold"> {{ "password" | translate }} </mat-panel-title>
        <mat-panel-description> {{ "passwordChange" | translate }} </mat-panel-description>

      </mat-expansion-panel-header>

      <div class="row">

        <div class="col-md-6">

          <mat-form-field class="w-100">
            <mat-label> {{ "newPassword" | translate }} </mat-label>
            <input matInput type="password" [(ngModel)]="newPassword">
          </mat-form-field>

        </div>
        <div class="col-md-3 offset-md-3">
          <button mat-raised-button color="primary" class="w-100" (click)="changePassword()"> {{ "update" | translate }} </button>
        </div>

      </div>
  
      
  
    </mat-expansion-panel>

  </mat-accordion>



</div>