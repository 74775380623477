import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, groupBy, map, tap } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TruckService } from "./truck.service";
import { Operation } from "./shared/data/operation";
import { environment } from "environments/environment";
import { Truck } from "./shared/data/truck";
import { Product } from "./shared/data/product";
import { WayPoint } from "./shared/data/waitPoint";
import { TruckDetails } from "./shared/data/operationTrucks";
import { Client } from "./shared/data/client";
import * as FileSaver from 'file-saver';

interface OperationDetails {
  data: [Operation[], Truck[], Client[], Product[], WayPoint[], Client[], Product[], WayPoint[]];
}

@Injectable({
  providedIn: "root",
})
export class OperationService {
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  prodUrl: string = `https://api.prod.tet4d.com`; 
  devUrl: string = `https://dev.api.tet4d.com`; 

  constructor(private http: HttpClient, private truckService: TruckService) { }

  getOperations() {
    // console.log("Fetching operations");
    return this.http
      .get<{ operations: Operation[] }>(`${environment.baseUrl}/operations`)
      .pipe(
        tap((_) =>{}),
        map((data) => {
          const operations = data.operations;
          const filteredOperations: Operation[] = [];
          operations.forEach((operation) => {
            const foundOperation = filteredOperations.find(
              (op) => op.id === operation.id
            );
            if (!foundOperation) {
              filteredOperations.push(operation);
            } else {
              if (foundOperation.status === "running") {
                foundOperation.status = operation.status;
                foundOperation.operation_message = operation.operation_message;
                foundOperation.timestamp = operation.timestamp;
              }
            }
          });
          return filteredOperations.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          );
        })
      );
  }

  getOperationsBoxes() {
    console.log("Fetching boxes from operations");
    return this.http
      .get<{ operations: Operation[] }>(`${environment.baseUrl}/operations/boxes`)
      .pipe(
        tap((_) => console.log("Boxes fetched")),
        map((data) => {
          return data
        })
      );
  }


  getOperationDetails(id: string) {
    console.log(`Fetching operation ${id}`);
    return this.http
      .get<OperationDetails>(`${environment.baseUrl}/operations/${id}`)
      .pipe(
        tap((_) => console.log(`Operation ${id} fetched`)),
        map((data) => {
          const trucks = data.data[1].sort((a, b) => a.id.localeCompare(b.id));
          const truckDetails = trucks.map((truck) => {
            const productsDB = data.data[3].filter(
              (product) => product.truckId === truck.plateNumber
            );

            const productsBQ = data.data[6].filter(
              (product) => product.truckId === truck.plateNumber
            );

            if (productsDB.length === 0) {
              truck.active = false;
            }

            const routeDB = data.data[4].filter(
              (route) => route.truckId === truck.plateNumber
            );
            const routeBQ = data.data[7].filter(
              (route) => route.truckId === truck.plateNumber
            );

            const uniqueClientsDB = [];
            const clientsDB = [
              ...data.data[2].filter((client) => {
                return routeDB.some((route) => route.clientId === client.id);
              }),
              ...data.data[2].filter((client) => {
                return productsDB.some((product) => product.clientRut === client.rut);
              })
            ]
            clientsDB.forEach((client) => {
              if (!uniqueClientsDB.some((uc) => client.id === uc.id && client.rut === uc.rut)) {
                uniqueClientsDB.push(client);
              }
            });

            const uniqueClientsBQ = [];
            const clientsBQ = [
              ...data.data[2].filter((client) => {
                return routeBQ.some((route) => route.clientId === client.id);
              }),
              ...data.data[2].filter((client) => {
                return productsBQ.some((product) => product.clientRut === client.rut);
              })
            ]
            clientsBQ.forEach((client) => {
              if (!uniqueClientsBQ.some((uc) => client.id === uc.id && client.rut === uc.rut)) {
                uniqueClientsBQ.push(client);
              }
            });

            const trips = productsBQ.reduce((prev, curr) => {
              if (!prev.includes(curr.trip_time)) {
                return prev.concat(curr.trip_time);
              }
              return prev;
            }, []);
            return {
              details: truck,
              clientsDB: uniqueClientsDB,
              clientsBQ: uniqueClientsBQ,
              clients: uniqueClientsDB,
              productsDB: productsDB.sort((p1, p2) =>
                p1.destination.localeCompare(p2.destination)
              ),
              productsBQ: productsBQ.sort((p1, p2) =>
                p1.destination.localeCompare(p2.destination)
              ),
              products: productsDB.sort((p1, p2) =>
                p1.destination.localeCompare(p2.destination)
              ),
              routeDB,
              routeBQ,
              route: routeDB,
              trips: trips.sort((a, b) => a - b),
            };
          });
          return { operation: data.data[0][0], trucks: truckDetails };
        })
      );
  }

  getOperationFiles(id: string, name: string) {

    console.log(`Fetching operation ${id} files`);
    return this.http
      .get<any>(`${environment.baseUrl}/operations/files/${id}/${name}`)
      .pipe(
        tap((_) => console.log(`Operation ${id} fetched`)),
        map((data) => {
          var blob = new Blob([data.csv], { type: "text/plain;charset=utf-8" });
          FileSaver.saveAs(blob, `${name}_${id}.csv`);
        })
      );
  }

  deleteOperation(id: string) {
    console.log(`Deleting operation ${id}`);
    return this.http.delete(`${environment.baseUrl}/operations/${id}`);
  }

  getTrucksInOperation() {
    var operationId: number = 1;
    this.truckService.getTrucksByOperation(operationId);
  }

  getLeftOut(operationId: string, prod: boolean): Observable<any> {
    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.get<any>(url+`/validator/operationleftout/${operationId}`).pipe(
      map((data) => {
        return data;
      })
    )
  }

  shipLeftout(operationId: string, leftOuts: any[]): Observable<any> {
    console.log(`shipping leftouts manually for operation ${operationId}`);
    return this.http.patch<any>(`${environment.baseUrl}/operations/${operationId}/shipLeftout`, leftOuts).pipe(
      map((data) => {
        return data;
      })
    );
  }

  authApiValidator(id: any, prod: boolean){
    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.post(url+`/validator/login?id=${id}`,{}).pipe(
      map((data: any) => {
        return data.token;
      })
    );
  }

  getMongoOperations(limit: number, offset: number, prod: boolean, operationName?: string, operationStatus?: string, initDate?: string, endDate?: string, operationType?: string){
    let url = prod ? this.prodUrl : this.devUrl;

    url += `/validator/operations?limit=${limit}&offset=${offset}`;

    if(operationName)
      url += `&operationName=${operationName}`;

    if(operationStatus)
      url += `&operationStatus=${operationStatus}`;

    if(initDate)
      url += `&initDate=${initDate}`;

    if(endDate)
      url += `&endDate=${endDate}`;

    if(operationType)
      url += `&clientOperationType=${operationType}`;


    return this.http
      .get( `${url}`)
      .pipe(
        tap((_) => console.log("Clients fetched")),
        map((data) => {

          console.log(data);
          return data;
          
        })
      );
  }

  getMongoRestoreOperations(limit: number, offset: number, prod: boolean, operationName?: string, operationStatus?: string, initDate?: string, endDate?: string, operationType?: string){
    let url = prod ? this.prodUrl : this.devUrl;

    url += `/validator/operationsRestore?limit=${limit}&offset=${offset}`;

    if(operationName)
      url += `&operationName=${operationName}`;

    if(operationStatus)
      url += `&operationStatus=${operationStatus}`;

    if(initDate)
      url += `&initDate=${initDate}`;

    if(endDate)
      url += `&endDate=${endDate}`;

    if(operationType)
      url += `&clientOperationType=${operationType}`;


    return this.http
      .get( `${url}`)
      .pipe(
        tap((_) => console.log("Clients fetched")),
        map((data) => {

          console.log(data);
          return data;
          
        })
      );
  }

  getMongoOperationsMultiRoute(limit: number, offset: number, prod: boolean, operationName?: string, operationStatus?: string, initDate?: string, endDate?: string, operationType?: string){
    let url = prod ? this.prodUrl : this.devUrl;

    url += `/validator/bulkOperation/operationsMultiRoute?limit=${limit}&offset=${offset}`;

    if(operationName)
      url += `&operationName=${operationName}`;

    if(operationStatus)
      url += `&operationStatus=${operationStatus}`;

    if(initDate)
      url += `&initDate=${initDate}`;

    if(endDate)
      url += `&endDate=${endDate}`;

    if(operationType)
      url += `&clientOperationType=${operationType}`;


    return this.http
      .get( `${url}`)
      .pipe(
        tap((_) => console.log("Clients fetched")),
        map((data) => {

          console.log(data);
          return data;
          
        })
      );
  }

  getTrackingOrders(id: string, prod: boolean){
    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.get(url+`/validator/operation/${id}/trackingOrders`).pipe(
      map((response) => {
        return response;
      },
      (error) => {
        return error;
      })
    )
  }

  getMongoOperation(id: string, prod: boolean){
    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.get(url+`/validator/operations/${id}`,)
      .pipe(
        tap((_) => console.log("mongo operation fetched")),
        map((data) => {
          return data;
          
        })
      );
  }

  deleteMongoOperation(id: string , prod: boolean){
    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.delete(url+`/validator/operation/${id}/deactivate`).pipe(
      map((response) => {
        return response;
      },
      (error) => {
        return error;
      })
    );
  }

  updateSentLastMile(id: string, prod: boolean){
    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.put(url+`/validator/operation/${id}/sentLastMile`, '').toPromise();
  }

  revertSentLastMile(id: string, prod: boolean){
    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.put(url+`/validator/operation/${id}/revertSentLastMile`, '').toPromise();
  }

  revertApproval(id: string, prod: boolean){
    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.put(url+`/validator/operation/${id}/revertApproval`, '').toPromise();
  }

  restoreOperation(id: string, prod: boolean){
    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.put(url+`/validator/operations/${id}/restore`, '').toPromise();
  }

  updateStatusApproval(id: string, status: string, driver: any, prod: boolean){
    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.put(url+`/validator/operation/${id}/updateStatusOperation`, {status, driver}).toPromise();
  }

  removeClientOrderFromOperation = (order: string, sku: string, operationId: string, prod: boolean) => {

    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.put(url+`/validator/operations/${operationId}/removeOrder`, {order, sku, operationId}).toPromise();

  }

  getMongoRoute = ( operationId: string, prod: boolean) => {
    let url = prod ? this.prodUrl : this.devUrl;

    return this.http.get<any>(url+`/validator/operations/${operationId}/routes`).pipe(
      map((data) => {
        return data;
      })
    )

  }

  sendPlannedRoute = (operationId: string, prod: boolean) => {

    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.put(url+`/validator/operation/${operationId}/sendPlannedRoute`, {}).toPromise();

  }

  getBulkOperationsForHome = (prod: boolean) => {

    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.get(url+`/validator/bulkOperation/getHomeInformation`).toPromise();

  }

  cleanOrderDocuments = (operationId: string, prod: boolean) => {

    let url = prod ? this.prodUrl : this.devUrl;
    return this.http.put(url+`/validator/operations/${operationId}/cleanOrderDocuments`, {}).toPromise();

  }

  resetRoute = (routeId: string, prod: boolean) => {

    const showDocuments = localStorage.getItem('companyTraceability') != 'order' ? true : false;

    let url = prod ? this.prodUrl : this.devUrl;

    if(showDocuments){
      return this.http.put(url+`/validator/routes/${routeId}/enableDocumentsRoute`, {}).toPromise();
    }else{
      return this.http.put(url+`/validator/routes/${routeId}/enableRoute`, {}).toPromise();
    }

    

  }

}
