import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { environment } from "environments/environment";
import { ClientService } from "./client.service";
import { TruckService } from "./truck.service";
import { AuthService } from "./shared/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class FileService {
  productsUpload: File = null;
  trucksUpload: File = null;
  clientsUpload: File = null;
  userId: string;

  constructor(
    private truckService: TruckService,
    private clientService: ClientService,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.userId = authService.getUserId();
  }

  manageUploadedFile(file: File, type: string, local: boolean = false): void {
    if (type === "products") {
      this.productsUpload = file;
    } else if (type === "trucks") {
      this.trucksUpload = file;
      if (local) {
        this.truckService
          .saveLocalTrucks(this.trucksUpload, this.userId)
          .subscribe((res) => {
            if (res.status === "ok") {
              console.log("file uploaded");
            } else {
              console.log("error uploading file");
            }
          });
      }
    } else if (type === "clients") {
      this.clientsUpload = file;
      if (local) {
        this.clientService
          .saveLocalClients(this.clientsUpload, this.userId)
          .subscribe((res) => {
            if (res.status === "ok") {
              console.log("file uploaded");
            } else {
              console.log("error uploading file");
            }
          });
      }
    } else if (type === "clusters") {
      this.clientsUpload = file;
      if (local) {
        this.clientService
          .saveLocalClusters(this.clientsUpload, this.userId)
          .subscribe((res) => {
            if (res.status === "ok") {
              console.log("file uploaded");
            } else {
              console.log("error uploading file");
            }
          });
      }
    }
  }

  loadExistingFiles(): Observable<string[]> {
    //get names of files from backend
    return of(["file1.csv", "file2.csv"]);
  }

  selectFiles(
    products: boolean,
    clients: boolean,
    trucks: boolean
  ): Observable<boolean> {
    if (
      !(Boolean(this.productsUpload) === products) ||
      !(Boolean(this.clientsUpload) === clients) ||
      !(Boolean(this.trucksUpload) === trucks)
    ) {
      return of(false);
    }
    console.log("Files uploaded");
    return of(true);
  }

  uploadFiles(files: any[]): Observable<any[]> {
    var parsedFiles = files.map(async (file) => {
      return;
    });
    console.log("parsed", parsedFiles);
    return of(parsedFiles);
  }

  optimize(config: any): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append("operation", this.productsUpload);
    formData.append("operation", this.trucksUpload);
    formData.append("operation", this.clientsUpload);
    formData.set("name", config.name);
    formData.set("codes", config.codes);
    formData.set("version", config.version);
    formData.set("automatic", config.automatic);
    formData.set("optimizePerVehicles", config.optimizePerVehicles);
    formData.set("optimizePerTotalVolumen", config.optimizePerTotalVolumen);
    formData.set("optimizePerDeliveryTime", config.optimizePerDeliveryTime);
    formData.set(
      "optimizePerDistanceTraveled",
      config.optimizePerDistanceTraveled
    );
    const request = new HttpRequest(
      "POST",
      `${environment.baseUrl}/operations`,
      formData
    );
    this.productsUpload = null;
    this.trucksUpload = null;
    this.clientsUpload = null;
    return this.http.request(request);
  }

  uploadFile(config: string): Observable<HttpEvent<any>> {
    const formData = new FormData();
    if (config === "trucks" && this.trucksUpload) {
      formData.append("trucks", this.trucksUpload);
    } else if (config === "clients" && this.clientsUpload) {
      formData.append("clients", this.clientsUpload);
    } else {
      throw new Error("File not found");
    }

    const request = new HttpRequest(
      "POST",
      `${environment.baseUrl}/${config}/local`,
      formData
    );

    return this.http.request(request);
  }
}
