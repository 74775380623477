import { Truck } from "../../app/shared/data/truck";

export const TRUCKS: Truck[] = [
  
]; 

// id: number;
// plateNumber: string;
// RUT: string;
// maxPayload: number;
// dimentions: BoxCoords;
// axes: number;
// type: string;
// available: boolean;
// productType: string;
// openClosed: string;
// dieselElectric: string;
// cost: string;